import * as _ from 'underscore';
import { WidgetData } from '@londonhydro/ui-framework-lib/widget-lib';

export class BatteryWidgets {}

export class BatteryCount extends WidgetData {
  constructor(value?: number, type?: string) {
    const iconClass = 'icon-battery' + (type ? '-' + type : '');
    super('Number Of Batteries', value, null, iconClass, 'info-tile');
  }
}

export class ChargerCount extends WidgetData {
  constructor(name: string, value?: number, iconClass?: string) {
    super(name, value, null, iconClass || 'icon-charger-public', 'info-tile');
  }
}

export class Charging extends WidgetData {
  constructor(value?: number, maxValue?: number, iconClass?: string, demand?: boolean) {
    iconClass = iconClass || 'icon-battery-charging';
    let unit = demand ? 'kW' : 'kWh';
    let mv = value;
    if (value && value > 1000) {
      mv = value / 1000;
      maxValue = maxValue ? maxValue / 1000 : maxValue;
      unit = demand ? 'MW' : 'MWh';
    }
    super('Charged', mv, unit, iconClass || 'icon-battery-charging', 'info-tile');
    this.maxValue = maxValue;
  }
}

export class Discharging extends WidgetData {
  constructor(value?: number, maxValue?: number, iconClass?: string, demand?: boolean) {
    let unit = demand ? 'kW' : 'kWh';
    let mv = value;
    if (value && value > 1000) {
      mv = value / 1000;
      maxValue = maxValue ? maxValue / 1000 : maxValue;
      unit = demand ? 'MW' : 'MWh';
    }
    super('Discharged', mv, unit, iconClass || 'icon-battery-discharging', 'info-tile');
    this.maxValue = maxValue;
    this.extraConfig = {};
    this.extraConfig.gaugeColor = '#e74133';
  }
}

export class ChargeRate extends WidgetData {
  constructor(value?: number, maxValue?: number, demand?: boolean) {
    let unit = demand ? 'kW' : 'kWh';
    let mv = value;
    if (value && value > 1000) {
      mv = value / 1000;
      maxValue = maxValue ? maxValue / 1000 : maxValue;
      unit = demand ? 'MW' : 'MWh';
    }
    super('Charge Rate', mv, unit, 'icon-charge-rate widget-icon-medium', 'info-tile bg-lightblue');
    this.extraConfig = {};
    this.extraConfig.labelClass = 'text-white';
    this.extraConfig.valueClass = 'text-white';
  }
}

export class ChargeRemaining extends WidgetData {
  constructor(value?: number) {
    const name = 'Charge Time Remaining';
    super(name, value, 'H', 'icon-charge-remaining widget-icon-medium', 'info-tile bg-palegreen');
    this.extraConfig = {};
    this.extraConfig.labelClass = 'text-white';
    this.extraConfig.valueClass = 'text-white';
  }
}

export class ChargePercentage extends WidgetData {
  constructor(value?: number) {
    const vp = value != null ? value * 100 : value;
    super('Charge Percentage', vp, null, 'icon-battery-charge widget-icon-large', 'info-tile bg-slateblue');
    this.extraConfig = {};
    this.extraConfig.valueInPercent = true;
    this.extraConfig.labelClass = 'text-white';
    this.extraConfig.valueClass = 'text-white';
  }
}

export class BatterySavings extends WidgetData {
  constructor(value?: number) {
    const name = 'Battery Savings';
    super(name, value, null, 'icon_solar_saving', 'simple-badge-tile no-box-shadow p-1');
    this.extraConfig = {};
    this.extraConfig.decimal = 2;
    this.extraConfig.valueInDollar = true;
    this.extraConfig.valueClass = 'battery value-medium';
  }
}
