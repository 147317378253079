import { WidgetConfig, WidgetDefinition } from '@londonhydro/ui-framework-lib/widget-lib';

export class HomeConsumptionWidget extends WidgetConfig {
  solarWidgetConfig: WidgetConfig;
  gridWidgetConfig: WidgetConfig;
  homeWidgetConfig: WidgetConfig;

  solarSource: number;
  gridSource: number;
  homeConsumption: number;
  solarPercentage: number;

  demand = false;
  animation = false;
  view: string;

  constructor(name?: string, data?: any, demand?: boolean, view?: string) {
    super(new WidgetDefinition(name));
    if (data) {
      this.homeConsumption = data.homeConsumption;
      // if (this.homeConsumption != null) {
      this.solarSource = data.solarSource || 0;
      this.gridSource = data.gridSource || 0;
      this.solarPercentage = (data.solarPercentage || 0) * 100;
      // }
    }
    if (demand != null) {
      this.demand = demand;
    }
    if(view != null){
      this.view = view;
    }
  }
}