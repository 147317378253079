import { HomeConsumptionWidget } from 'src/app/framework/ui-lib/widget/config/homeconsumptionwidget';
import { Generation, Consumption, CO2Offset, CO2OffsetLeaf, Capacity, Performance, ConsumptionCost, TotalEnergyCost } from 'src/app/framework/ui-lib/widget/config/utility-widgets';
import { SolarPerformance, SolarSavings } from 'src/app/framework/ui-lib/widget/config/solar-widgets';

export class SolarAssetWidgets {

   static HomeConsumptionWidget(data?: any, demand?: boolean, view?: string): HomeConsumptionWidget {
    data = data || {};
    data.assetProfile = data.assetProfile || data;
    const widgetData =  {
      homeConsumption: data.consumption,
      solarSource: data.assetProfile.generation,
      // gridSource: data.netConsumption,
      // gridSource: data.consumption !== null ? Math.max((data.consumption || 0) - (data.assetProfile.generation || 0), 0) : null,
      gridSource: data.netConsumption ? data.netConsumption : (data.netGeneration ? -data.netGeneration : null),
      solarPercentage : data.solarPercentage
    };
    const hcw = new HomeConsumptionWidget('Home Consumption', widgetData, demand, view);
    hcw.widgetClass = 'no-box-shadow p-0';
    return hcw;
  }

  static CurrentGeneration(value?: number, demand?: boolean): Generation {
    const solarGeneration = new Generation(value, null, 'icon-current-generation-asset', demand);
    solarGeneration.widgetClass += ' bg-yellow';
    solarGeneration.extraConfig = solarGeneration.extraConfig || {};
    solarGeneration.extraConfig.labelClass = 'text-white';
    solarGeneration.extraConfig.valueClass = 'text-white';
    return solarGeneration;
  }

  static MySolarConsumption(value?: number, demand?: boolean): Consumption {
    const myConsumption = new Consumption(value, null, true, 'icon-my-solar-consumption', demand);
    myConsumption.name = 'My Solar Consumption';
    myConsumption.widgetClass += ' bg-orange';
    myConsumption.extraConfig.labelClass = 'text-white';
    myConsumption.extraConfig.valueClass = 'text-white';

    return myConsumption;
  }

  static CO2Offset(value: any, view: string): CO2Offset {
    const widget = new CO2Offset(value, view);
    widget.widgetClass += ' bg-transparent no-box-shadow p-0';
    return widget;
  }

  static CO2OffsetLeaf(value?: any, view?: string): CO2OffsetLeaf {
    return new CO2OffsetLeaf(value, view);
  }

  static SolarPerformance(value?: number): SolarPerformance {
    const solarPerformance = new SolarPerformance(value);
    solarPerformance.name = 'Solar Performance';
    return solarPerformance;
  }

  static CurrentPerformance(value?: number): Performance {
    const currentPerformance = new Performance(value);
    currentPerformance.name = 'Performance';
    currentPerformance.extraConfig = currentPerformance.extraConfig || {};
    currentPerformance.extraConfig.decimal = 0;
    return currentPerformance;
  }

  static SolarGeneration(value?: number, demand?: boolean): Generation {
    const sGeneration =  new Generation(value, null, null, demand);
    sGeneration.widgetClass += ' no-box-shadow p-0';
    sGeneration.extraConfig = {};
    sGeneration.extraConfig.valueClass = 'generation value-medium';
    return sGeneration;
  }

  static SolarNameplate(value?: number, demand?: boolean): Capacity {
    const sCapacity =  new Capacity(value, null, null, demand);
    sCapacity.name = 'Expected Generation';
    // sCapacity.title = 'Expected Generation';
    sCapacity.title = 'Predicted solar generation for this site';
    sCapacity.widgetClass += ' no-box-shadow p-0';
    sCapacity.extraConfig = {};
    sCapacity.extraConfig.valueClass = 'solar value-medium';
    return sCapacity;
  }

  static ConsumptionCost(value?: number): ConsumptionCost {
    return new ConsumptionCost(value);
  }

  static SolarSavings(value?: number): SolarSavings {
    return new SolarSavings(value);
  }

  static TotalEnergyCost(value?: number): TotalEnergyCost {
    return new TotalEnergyCost(value);
  }
}
