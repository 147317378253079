import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { UIUtil, SummaryView } from '@londonhydro/ui-framework-lib/ui-util-lib';


@Injectable()
export class ElectricVehiclesService {

  constructor(private http: HttpClient) { }

  getAssetProfiles(programName: string, assetType: string): Observable<any> {
    const evAssetLocationsDataUrl = `/ev/asset/profile?programName=${programName}&assetType=${assetType}`;

    return this.http.get<any>(environment.derMsEndPoint + evAssetLocationsDataUrl)
      .pipe(
        tap(res => { console.log('fetched asset locations', res); }),
        catchError(this.handleError('SolarAssetLocations', null))
      );
  }

  getDerProfileForProgram(view: string, startDate: Date, endDate: Date): Observable<any> {
    let evProfileApiUrl: string;
    const iStartDate = moment.tz(startDate, UIUtil.UiTimeZone).format(UIUtil.UiDateApiInputFormat);
    const iEndDate = moment.tz(endDate, UIUtil.UiTimeZone).format(UIUtil.UiDateApiInputFormat);
    const communityName = 'L2L';

    let period: string;
    if (view === SummaryView.YEARLY) {
      period = 'Yearly';
    } else if (view === SummaryView.MONTHLY) {
      period = 'Monthly';
    } else if (view === SummaryView.WEEKLY) {
      period = 'Weekly';
    } else {
      period = 'Daily';
    }
    evProfileApiUrl = `/evprofile/community?communityName=${communityName}&startDate=${iStartDate}&endDate=${iEndDate}&period=${period}`;
    console.log('evProfileApiUrl:::' + evProfileApiUrl);

    return this.http.get<any>(environment.derMsEndPoint + evProfileApiUrl)
      .pipe(
        tap(res => { console.log('fetched Solar daily consumption', res); }),
        catchError(this.handleError('Solar daily consumption', null))
      );
  }

  getAssetConsumptionData(programName: string, view: string, startDate: Date, endDate: Date): Observable<any> {
    let evConsumptionApiUrl: string;

    if (startDate === endDate) {
      endDate = moment.tz(startDate, UIUtil.UiTimeZone).startOf('day').add(1, 'day').toDate();
    }
    const iStartDate = moment.tz(startDate, UIUtil.UiTimeZone).format(UIUtil.UiDateApiInputFormat);
    const iEndDate = moment.tz(endDate, UIUtil.UiTimeZone).format(UIUtil.UiDateApiInputFormat);

    let period: string;
    if (view === SummaryView.YEARLY) {
      period = SummaryView.MONTHLY;
    } else if (view === SummaryView.MONTHLY) {
      period = 'Daily';
    } else if (view === SummaryView.WEEKLY) {
      period = 'Daily';
    } else if (view === SummaryView.DAILY) {
      period = 'Hourly';
    } else {
      period = 'Daily';
    }
    evConsumptionApiUrl = `/evprofile/community?communityName=${programName}&startDate=${iStartDate}&endDate=${iEndDate}&period=${period}`;

    console.log('derProfileApiUrl:::' + evConsumptionApiUrl);

    return this.http.get<any>(environment.derMsEndPoint + evConsumptionApiUrl)
      .pipe(
        tap(res => { console.log('fetched Asset daily consumption..'); }),
        catchError(this.handleError('Solar daily consumption', null))
      );
  }

  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.error(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
