import { BatteryCount, Discharging, Charging } from 'src/app/framework/ui-lib/widget/config/battery-widgets';
import { CO2Offset, SiteCount, Capacity } from 'src/app/framework/ui-lib/widget/config/utility-widgets';

export class BatteryWidgets {

  static BatteryCountResidential(value?: number): BatteryCount {
    return new BatteryCount(value, 'residential');
  }

  static BatteryCountCommercial(value?: number): BatteryCount {
    return new BatteryCount(value, 'commercial');
  }

  static Discharging(value?: number, maxValue?: number, demand?: boolean): Discharging {
    return new Discharging(value, maxValue, null, demand);
  }

  static Charging(value?: number, maxValue?: number, demand?: boolean): Charging {
    return new Charging(value, maxValue, null, demand);
  }

  static CO2Offset(value?: any): CO2Offset {
    return new CO2Offset(value);
  }

  static SiteCountResidential(value?: number): SiteCount {
    const iconClass = 'icon-battery-residential icon-circle residential';
    return new SiteCount(value, iconClass);
  }

  static SiteCountCommercial(value?: number): SiteCount {
    const iconClass = 'icon-battery-commercial icon-circle commercial';
    return new SiteCount(value, iconClass);
  }

  static Capacity(value?: number, maxValue?: number, demand?: boolean): Capacity {
    const iconClass = 'icon-battery-capacity';
    return new Capacity(value, maxValue, iconClass, demand);
  }
}

